import { AxiosError } from "axios";

import { MessagingApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";
import { MessagingTrigger } from "@typings";

export const useFetchTriggersByCampaignID = ({
  campaign_id,
  options,
}: {
  campaign_id: string;
  options?: Omit<UseQueryOptions<MessagingTrigger[], AxiosError>, "queryKey" | "queryFn">;
}): UseQueryResult<MessagingTrigger[], AxiosError> => {
  return useQuery({
    queryKey: [QUERY_KEYS.MESSAGING_TRIGGERS, campaign_id],
    queryFn: () => MessagingApi.getTriggers(context.active(), { campaign_id }),
    ...options,
  });
};
