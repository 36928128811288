import { AxiosError } from "axios";
import { InterviewsConfiguration } from "typings/interviews";

import { InterviewApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";

export const useFetchInterviewsConfigurationByCampaignID = ({
  campaignId,
  options,
}: {
  campaignId: string;
  options?: Omit<UseQueryOptions<InterviewsConfiguration | undefined, AxiosError>, "queryKey" | "queryFn">;
}): UseQueryResult<InterviewsConfiguration | undefined, AxiosError> => {
  return useQuery({
    queryKey: [QUERY_KEYS.INTERVIEW_CONFIGURATION, campaignId],
    queryFn: () => InterviewApi.getConfiguration(context.active(), campaignId),
    ...options,
  });
};
