import { FC, useMemo } from "react";

import { AxiosError } from "axios";
import { ApplicationDocuments } from "components/application/application-documents";
import { ApplicationNameStatus } from "components/application/application-name-status";
import { ApplicationQualification } from "components/application/application-qualification";
import { useApplicationWithJobInfo } from "hooks/queries/applications";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { Box, Stack } from "@mui/material";

import { InfoBox } from "@work4Labs/design-system";

import { AuthProxyAPI, ScoringAPI, SourcingCampaignApi } from "@api";
import { ApplicationInterview, BasicTab, CallInProgressAlert, CommentList, ItemTab, Spinner } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useQuery } from "@tanstack/react-query";
import { ORGANIZATIONS_FEATURES, SourcingCampaign } from "@typings";

import {
  useCurrentUserOrganization,
  useFetchInterviewsConfigurationByCampaignID,
  useOrganizationFeatures,
} from "@hooks/queries";

type ApplicationDetailsProps = {
  applicationID: string;
};

export const ApplicationDetails: FC<ApplicationDetailsProps> = ({ applicationID }) => {
  const { t } = useTranslation(["application-details"]);
  loadTranslations("application-details");

  const { data: session } = useSession();

  const { data: application } = useApplicationWithJobInfo(applicationID);

  const currentOrganizationQuery = useCurrentUserOrganization(session?.user?.id);

  const { hasFeature } = useOrganizationFeatures(currentOrganizationQuery.data?.group_id ?? "", {
    enabled: currentOrganizationQuery.data?.group_id !== undefined,
  });

  const candidateQuery = useQuery({
    queryKey: [QUERY_KEYS.CANDIDATES, application?.candidate_id],
    queryFn: () => AuthProxyAPI.getCandidate(context.active(), String(application?.candidate_id)),
    enabled: !!application,
  });

  const sourcingCampaignQuery = useQuery<SourcingCampaign, AxiosError>({
    queryKey: [QUERY_KEYS.SOURCING_CAMPAIGNS, application?.campaign_id],
    queryFn: () => SourcingCampaignApi.get(context.active(), application?.campaign_id as string),
    enabled: !!application?.campaign_id,
  });

  const { data: applicationsScore } = useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_ALL_SCORES, application?.campaign_id],
    queryFn: () => ScoringAPI.getAllScores(context.active(), application?.campaign_id as string),
    staleTime: Infinity,
    enabled: !!application?.campaign_id,
  });

  const { data: interviewConfiguration } = useFetchInterviewsConfigurationByCampaignID({
    campaignId: application?.campaign_id as string,
    options: {
      enabled: !!application?.campaign_id,
    },
  });

  const applicationScore = useMemo(() => applicationsScore?.[applicationID], [applicationID, applicationsScore]);

  if (!candidateQuery.data || !application) {
    return (
      <Stack flexDirection="row" flexGrow={1} alignSelf="stretch" role="presentation">
        <Spinner style={{ position: "relative" }} />
      </Stack>
    );
  }

  return (
    <Stack flexGrow={4} flexDirection="row" overflow="hidden" flexWrap="nowrap" alignSelf="stretch" role="presentation">
      <CallInProgressAlert />

      <Stack
        id="application_content"
        overflow="auto"
        flexGrow={1}
        alignSelf="stretch"
        borderLeft={(theme) => `1px solid ${theme.palette.color.pastelPurple}`}
      >
        {sourcingCampaignQuery.data && (
          <ApplicationNameStatus
            sourcingCampaign={sourcingCampaignQuery.data}
            candidate={candidateQuery.data}
            application={application}
            applicationScore={applicationScore}
          />
        )}
        {sourcingCampaignQuery.data == null && !sourcingCampaignQuery.isPending && (
          <Box padding={(theme) => theme.spacings[8]}>
            <InfoBox level="warning">{t("errors.noCampaign")}</InfoBox>
          </Box>
        )}
        <BasicTab
          style={{
            marginTop: "1.5rem",
            paddingLeft: "40px",
            overflowWrap: "anywhere",
          }}
          tabs={{
            [t("tabs.qualifications")]: {
              node: <ApplicationQualification application={application} />,
            } as ItemTab,
            [t("tabs.documents")]: {
              node: <ApplicationDocuments applicationID={application.id} />,
              hidden: !hasFeature(ORGANIZATIONS_FEATURES.APPLICATIONS_DOCUMENTS),
            } as ItemTab,
            [t("tabs.interviews")]: {
              node: sourcingCampaignQuery.data && (
                <ApplicationInterview
                  sourcingCampaign={sourcingCampaignQuery.data}
                  applicationID={application.id}
                  interviewConfiguration={interviewConfiguration}
                />
              ),
              hidden: interviewConfiguration === undefined || !sourcingCampaignQuery.data,
            } as ItemTab,
          }}
        />
      </Stack>

      <Stack
        overflow="auto"
        flexGrow={1}
        flexShrink={0}
        alignSelf="stretch"
        paddingBottom="1rem"
        width="40rem"
        maxWidth="40vw"
        borderLeft={(theme) => `1px solid ${theme.palette.color.pastelPurple}`}
      >
        {sourcingCampaignQuery.data && application && <CommentList application={application}></CommentList>}
      </Stack>
    </Stack>
  );
};
