import { FC, useMemo, useState } from "react";

import { useTranslation } from "next-i18next";

import { Box, Button, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";

import { loadTranslations } from "@lib";
import { useApplicationsStore } from "@stores";
import { ApplicationListItem } from "@typings";

import { useOTELContext, useOTELSpan, useUserGroup } from "@hooks";
import { useFetchCampaignApplicationsList, useFetchJobApplicationsList } from "@hooks/queries";

import { ListItem } from "./list-item";

const SORT_OPTIONS = [
  { label: "most_recent", value: "most_recent" },
  { label: "most_ancient", value: "most_ancient" },
  { label: "a-z", value: "a-z" },
  { label: "z-a", value: "z-a" },
];

type Props =
  | {
      campaignID: string;
      jobID?: never;
    }
  | {
      campaignID?: never;
      jobID: string;
    };

export const ApplicationsNavigation: FC<Props> = ({ campaignID, jobID }) => {
  const { t } = useTranslation(["campaign-applications-navigation"]);
  loadTranslations("campaign-applications-navigation");

  const { ctx, span } = useOTELSpan(
    useOTELContext(),
    campaignID ? "CampaignApplicationNavigation" : "JobApplicationNavigation",
  );

  const organizationName = useUserGroup();

  const { applications: campaignApplications } = useFetchCampaignApplicationsList(ctx, organizationName, campaignID);
  const { applications: jobApplications } = useFetchJobApplicationsList(ctx, organizationName, jobID);

  const applications = useMemo(
    () => (campaignID ? campaignApplications : jobApplications),
    [campaignID, campaignApplications, jobApplications],
  );

  span.end();

  const applicationsID = useApplicationsStore((state) => state.applicationsID);

  const [sortValue, setSortValue] = useState<string>("most_recent");
  const [searchValue, setSearchValue] = useState<string>("");

  const treatedApplications = useMemo(() => {
    let apps: ApplicationListItem[] = applications.filter((application) => applicationsID.includes(application.id));
    if (searchValue !== "") {
      apps = apps.filter((application) => {
        return (
          application.candidate?.first_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          application.candidate?.last_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          application.job?.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
          application.candidate?.phone?.toLowerCase().includes(searchValue.toLowerCase()) ||
          application.candidate?.email?.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    }

    if (sortValue === "most_recent") {
      apps = apps.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    } else if (sortValue === "most_ancient") {
      apps = apps.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    } else if (sortValue === "a-z") {
      apps = apps.sort((a, b) => {
        const nameA = a.candidate?.first_name?.toLowerCase() + " " + a.candidate?.last_name?.toLowerCase() || "";
        const nameB = b.candidate?.first_name?.toLowerCase() + " " + b.candidate?.last_name?.toLowerCase() || "";
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else if (sortValue === "z-a") {
      apps = apps.sort((a, b) => {
        const nameA = a.candidate?.first_name?.toLowerCase() + " " + a.candidate?.last_name?.toLowerCase() || "";
        const nameB = b.candidate?.first_name?.toLowerCase() + " " + b.candidate?.last_name?.toLowerCase() || "";
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    }
    return apps;
  }, [applications, applicationsID, searchValue, sortValue]);

  return (
    <Stack
      alignSelf="stretch"
      alignItems="stretch"
      overflow="hidden"
      gap="1rem"
      flexDirection="column"
      width="24rem"
      maxWidth="20vw"
      flexShrink={0}
      padding="1rem"
      id="application_left_side_menu"
    >
      <Stack flexWrap="nowrap" flexDirection="row" gap="1rem">
        <Box flexGrow={1}>
          <TextField
            id="application_search_field"
            size="small"
            fullWidth
            placeholder={t("search", { count: applicationsID.length })}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={(theme) => ({
              ...theme.typography.body,
              backgroundColor: theme.palette.color.BASE[100],
              height: "100%",
              "& .MuiInputBase-root": {
                height: "100%",
              },
              "& input": {
                fontSize: theme.fontSize[85],
              },
            })}
          />
        </Box>
        <Box>
          <Select
            id="application_sort_field"
            size="small"
            label={<Typography variant="button">{t("sort_by")}</Typography>}
            notched={false}
            value={sortValue}
            onChange={(e) => setSortValue(e.target.value)}
            renderValue={() => <Typography variant="button">{t("sort_by")}</Typography>}
          >
            {SORT_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(`sort_by_options.${option.value}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Stack>
      <Stack flexDirection="column" gap="0.5rem" overflow="auto" flexGrow={1}>
        {treatedApplications.length > 0 ? (
          treatedApplications.map((application) => <ListItem key={application.id} application={application} />)
        ) : (
          <NoResults resetFilter={() => setSearchValue("")} />
        )}
      </Stack>
    </Stack>
  );
};

const NoResults = ({ resetFilter }: { resetFilter: () => void }) => {
  const { t } = useTranslation(["applications-navigation"]);
  loadTranslations("applications-navigation");

  return (
    <Box
      id="no_results"
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "1rem",
        backgroundColor: "white",
        gap: theme.spacings[24],
      })}
    >
      <Typography variant="body" textAlign="center">
        {t("no_results")}
      </Typography>
      <Button variant="contained" color="deepPurple" onClick={resetFilter}>
        {t("reset_filter")}
      </Button>
    </Box>
  );
};
