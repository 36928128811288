import { ApplicationApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Question } from "@typings";
import { Logger } from "@utils";

import { useUserGroup } from "@hooks";

export const useCampaignQuestionsQuery = (campaignID: string | undefined) => {
  const userGroup = useUserGroup();

  return useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGN_APPLICATIONS_QUESTIONS, campaignID, userGroup],
    queryFn: () =>
      ApplicationApi.listQuestions(context.active(), { campaign_id: campaignID, organization_names: [userGroup] }),
    enabled: !!campaignID && !!userGroup,
  });
};

export const useCampaignQuestionsQueries = (...campaignIDs: string[]) => {
  const userGroup = useUserGroup();

  return useQueries({
    queries: campaignIDs.map((campaignID) => ({
      queryKey: [QUERY_KEYS.CAMPAIGN_APPLICATIONS_QUESTIONS, campaignID, userGroup],
      queryFn: () =>
        ApplicationApi.listQuestions(context.active(), { campaign_id: campaignID, organization_names: [userGroup] }),
      enabled: !!campaignID && !!userGroup,
    })),
    combine: (results): Question[] =>
      results.reduce((acc, queryResult) => {
        if (queryResult.isError) {
          Logger.error(queryResult.error);
          return acc;
        }

        if (queryResult.data) {
          acc.push(...queryResult.data.filter((question) => !acc.some((q) => q.id === question.id)));
        }

        return acc;
      }, [] as Question[]),
  });
};
