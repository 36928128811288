import { useCallback, useState } from "react";

import { MRT_RowData, MRT_TableOptions, useMaterialReactTable } from "material-react-table";
import { useSession } from "next-auth/react";
import * as yup from "yup";

import { tablePaginationClasses } from "@mui/material";

import { SyncMergeProps, renderEmptyRowsFallback, useSyncedState } from "@components";
import { MRT_Localization_EN, MRT_Localization_FR } from "@locales";
import { ColumnFiltersState, Row, RowSelectionState } from "@tanstack/react-table";

const columnsFilterSchema = yup
  .array()
  .of(
    yup.object({
      id: yup.string().required(),
      value: yup.array().of(yup.string().required()).required(),
    }),
  )
  .required();

/**
 * Our custom column filters. We only support array of strings for sake of simplicity.
 */
export interface ManagedColumnFilter {
  id: string;
  value: string[];
  flat?: boolean;
  stringified?: boolean;
}

/**
 * Ensure the value for columns visibility that is saved locally has not been tampered, and represents a valid object
 * of boolean values.
 */
const validateColumnsVisibility = (raw: string | null): Record<string, boolean> | undefined => {
  if (raw == null || raw === "") return undefined;

  const value = JSON.parse(raw);
  if (typeof value !== "object") {
    throw new Error("Invalid columns visibility data: not an object");
  }
  if (Object.values(value as Record<string, any>).some((v) => typeof v !== "boolean")) {
    throw new Error("Invalid columns visibility data: not all values are booleans");
  }
  return value;
};

const concatUniqFilters = (prev: ManagedColumnFilter[], next: ManagedColumnFilter[]): ManagedColumnFilter[] => {
  const newValues = next.filter((nextFilter) => !prev.some((prevFilter) => prevFilter.id === nextFilter.id));
  return [...prev, ...newValues];
};

/**
 * Merge together column filters values from different sources (localStorage, URL and memory).
 */
const mergeColumnFilters = (props: SyncMergeProps<ManagedColumnFilter[]>, isFirst: boolean): ManagedColumnFilter[] => {
  return isFirst ? concatUniqFilters(props.localStorage ?? [], props.url ?? []) : props.inMemory ?? [];
};

/**
 * Merge together global filters values from different sources (localStorage, URL and memory).
 */
const mergeGlobalFilters = (props: SyncMergeProps<string>, isFirst: boolean): string | undefined => {
  return (isFirst ? props.url : props.inMemory) || undefined;
};

/**
 * Merge together column visibility values from different sources (localStorage, URL and memory).
 */
const mergeColumnVisibility = (
  props: SyncMergeProps<Record<string, boolean>>,
  isFirst: boolean,
): Record<string, boolean> | undefined => {
  return isFirst ? props.localStorage : props.inMemory;
};

/**
 * Convert any value to an array of string, for filters.
 */
const unknownToColumnFilter = (src: unknown): Omit<ManagedColumnFilter, "id"> => {
  if (src == null) return { value: [], flat: false, stringified: false };
  if (Array.isArray(src)) return { value: src.map((v) => `${v}`), flat: false, stringified: false };
  return typeof src === "string"
    ? { value: [src], flat: true, stringified: false }
    : { value: [JSON.stringify(src)], flat: true, stringified: true };
};

/**
 * Convert the raw column filters provided by Material React Table to our internal managed type. Non-Array values are
 * converted to an array of one element. Nullish values are discarded. Other values are converted to string.
 */
export const columnFilterStateToManaged = (src: ColumnFiltersState): ManagedColumnFilter[] => {
  return src
    .filter(
      ({ value }) =>
        // Don't cache empty values
        !!value && (!Array.isArray(value) || value.length > 0),
    )
    .map(({ id, value }) => ({ id, ...unknownToColumnFilter(value) }));
};

const INITIAL_COLUMNS_FILTERS = [];
const INITIAL_COLUMNS_VISIBILITY = {};

export type StateHookLike<T> = (value: T | ((prevState: T) => T)) => void;

export interface UseTableFiltersProps {
  cacheKey: string;
  initialColumnsVisibility?: Record<string, boolean>;
  initialColumnFilters?: ManagedColumnFilter[];
  onBeforeSaveColumnFilters?: (
    value: ManagedColumnFilter[] | undefined,
    destination: "url" | "localStorage",
  ) => ManagedColumnFilter[] | undefined;
  onBeforeSaveGlobalFilters?: (value: string | undefined, destination: "url" | "localStorage") => string | undefined;
  onBeforeSaveColumnsVisibility?: (
    value: Record<string, boolean> | undefined,
    destination: "url" | "localStorage",
  ) => Record<string, boolean> | undefined;
}

export interface UseTabFiltersHook {
  columnFilters: ManagedColumnFilter[] | undefined;
  globalFilter: string | undefined;
  columnVisibility: Record<string, boolean> | undefined;
  setColumnFilters: StateHookLike<ManagedColumnFilter[] | undefined>;
  setGlobalFilter: StateHookLike<string | undefined>;
  setColumnVisibility: StateHookLike<Record<string, boolean> | undefined>;
}

export const useTableFilters = ({
  cacheKey,
  initialColumnsVisibility,
  initialColumnFilters,
  onBeforeSaveColumnFilters,
  onBeforeSaveColumnsVisibility,
  onBeforeSaveGlobalFilters,
}: UseTableFiltersProps): UseTabFiltersHook => {
  const parseColumnFilters = useCallback((raw: string) => columnsFilterSchema.validateSync(JSON.parse(raw)), []);
  const [columnFilters, setColumnFilters] = useSyncedState({
    cacheKey: `${cacheKey}_columns_filters`,
    urlKey: "columns",
    url: true,
    localStorage: true,
    initialValue: initialColumnFilters ?? INITIAL_COLUMNS_FILTERS,
    parse: parseColumnFilters,
    onBeforeSave: onBeforeSaveColumnFilters,
    onMerge: mergeColumnFilters,
  });

  const parseGlobalFilters = useCallback((raw: string) => JSON.parse(raw), []);
  const [globalFilter, setGlobalFilter] = useSyncedState({
    cacheKey: `${cacheKey}_global_filter`,
    urlKey: "global_filter",
    url: true,
    initialValue: "",
    parse: parseGlobalFilters,
    onBeforeSave: onBeforeSaveGlobalFilters,
    onMerge: mergeGlobalFilters,
  });

  const parseColumnsVisibility = useCallback((raw: string) => validateColumnsVisibility(raw), []);
  const [columnVisibility, setColumnVisibility] = useSyncedState({
    cacheKey: `${cacheKey}_columns_visibility`,
    localStorage: true,
    initialValue: initialColumnsVisibility ?? INITIAL_COLUMNS_VISIBILITY,
    parse: parseColumnsVisibility,
    onBeforeSave: onBeforeSaveColumnsVisibility,
    onMerge: mergeColumnVisibility,
  });

  return {
    columnFilters,
    globalFilter,
    columnVisibility,
    setColumnFilters,
    setGlobalFilter,
    setColumnVisibility,
  };
};

export const useManagedMaterialReactTable = <TData extends MRT_RowData>(
  tableOptions: Omit<MRT_TableOptions<TData>, "localization"> & {
    localization?: MRT_TableOptions<TData>["localization"];
    seizaFilters: UseTableFiltersProps;
  },
) => {
  const { data: session } = useSession();
  const { seizaFilters, ...restTableOptions } = tableOptions;

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const filters = useTableFilters(seizaFilters);

  return useMaterialReactTable({
    localization: session?.user?.locale === "en" ? MRT_Localization_EN : MRT_Localization_FR,
    selectAllMode: "all",
    paginationDisplayMode: "pages",
    positionActionsColumn: "last",
    globalFilterFn: "fuzzy",
    defaultColumn: {
      muiTableHeadCellProps: { sx: (theme) => ({ ...theme.typography.body, color: theme.palette.color.BASE[500] }) },
      muiTableBodyCellProps: (props) => ({
        sx: (theme) => ({ ...theme.typography.body, color: theme.palette.text.mainInfo, cursor: "pointer" }),
        ...(typeof restTableOptions.muiTableBodyCellProps === "function"
          ? restTableOptions.muiTableBodyCellProps(props)
          : restTableOptions.muiTableBodyCellProps),
      }),
    },
    onColumnFiltersChange: (newColumnFilters) => {
      setRowSelection({});
      filters.setColumnFilters((prevState) => {
        const parsedNewFilters =
          typeof newColumnFilters === "function" ? newColumnFilters(prevState ?? []) : newColumnFilters;
        return columnFilterStateToManaged(parsedNewFilters);
      });
    },
    onGlobalFilterChange: (newGlobalFilter) => {
      setRowSelection({});
      filters.setGlobalFilter((prevState) => {
        return typeof newGlobalFilter === "function" ? newGlobalFilter(prevState ?? "") : newGlobalFilter;
      });
    },
    onRowSelectionChange: (newRowSelection) => {
      setRowSelection(newRowSelection);
    },
    onColumnVisibilityChange: (newColumnVisibility) => {
      setRowSelection({});
      filters.setColumnVisibility((prevColumnVisibility) => {
        const actualNewFilters =
          typeof newColumnVisibility === "function"
            ? newColumnVisibility(prevColumnVisibility ?? {})
            : newColumnVisibility;

        return { ...prevColumnVisibility, ...actualNewFilters };
      });
    },
    muiPaginationProps: { color: "deepPurple", rowsPerPageOptions: [10, 25, 50] },
    muiBottomToolbarProps: {
      sx: (theme) => ({
        [`& .${tablePaginationClasses.root} label`]: {
          ...theme.typography.tags,
          color: theme.palette.color.BASE[800],
        },
        [`& .${tablePaginationClasses.root} .MuiSelect-select`]: {
          ...theme.typography.tagsStrong,
          color: theme.palette.color.BASE[800],
        },
      }),
    },
    renderEmptyRowsFallback: renderEmptyRowsFallback,
    ...restTableOptions,
    filterFns: {
      ...restTableOptions.filterFns,
      arrayIncludes: (row: Row<TData>, columnId: string, filterValue: string[] | null) =>
        filterValue === null || filterValue?.length === 0 || filterValue?.includes(row.getValue(columnId)),
    },
    state: {
      globalFilter: filters.globalFilter,
      columnFilters: filters.columnFilters ?? [],
      columnVisibility: filters.columnVisibility,
      rowSelection,
      ...restTableOptions.state,
    },
  });
};
