import { ApplicationApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { UndefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { Status } from "@typings";

export const useFetchApplicationStatuses = (
  options?: Partial<UndefinedInitialDataOptions<Status[], Error, Status[], string[]>>,
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_STATUSES],
    queryFn: () => ApplicationApi.listStatuses(context.active()),
    refetchOnWindowFocus: true,
    gcTime: Infinity,
    ...options,
  });
};
