import { useMemo, useState } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import { useTheme } from "@mui/material";

import { GroupAction } from "@components";
import { UserPermissions } from "@constants";
import { loadTranslations } from "@lib";

import { useHasPermissions } from "@hooks";

import { ListJobItemWithStats } from "../types";

import { DeleteJobsModal } from "../modals/delete-jobs-modal";

export const useJobsGroupActions = (table: MRT_TableInstance<ListJobItemWithStats>) => {
  const { t } = useTranslation(["jobs-grouped-actions"]);
  loadTranslations("jobs-grouped-actions");

  const theme = useTheme();

  const [isDeleteJobsModalOpen, setIsDeleteJobsModalOpen] = useState(false);

  const canDeleteJobs = useHasPermissions([UserPermissions.JobsPermissions.Delete]);

  const { getSelectedRowModel, resetRowSelection } = table;

  const groupActions = useMemo<GroupAction<ListJobItemWithStats>[]>(
    () => [
      {
        display: (selected) => (
          <span style={{ fontSize: "inherit", color: theme.palette.color.ALERT_RED[400] }}>
            {t("delete_jobs", { count: selected.length, ns: "jobs-grouped-actions" })}
          </span>
        ),
        onClick: () => setIsDeleteJobsModalOpen(true),
        enabled: canDeleteJobs,
      },
    ],
    [canDeleteJobs, t, theme.palette.color.ALERT_RED],
  );

  const selectedJobs = getSelectedRowModel().rows.map((row) => row.original);

  const modals = (
    <>
      {isDeleteJobsModalOpen ? (
        <DeleteJobsModal
          jobs={selectedJobs}
          open={isDeleteJobsModalOpen}
          setOpen={setIsDeleteJobsModalOpen}
          onSuccess={resetRowSelection}
        />
      ) : null}
    </>
  );

  return { groupActions, groupActionsModals: modals };
};
