import Link from "next/link";
import { useRouter } from "next/router";

import { FC, MouseEvent, ReactNode, forwardRef, useCallback, useMemo, useRef, useState } from "react";

import { AxiosError } from "axios";
import moment from "moment/moment";
import { useTranslation } from "next-i18next";
import { TFunction } from "react-i18next";

import {
  ArrowDropDown as ArrowDropDownIcon,
  DateRange as DateRangeIcon,
  KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
  PersonSearch as PersonSearchIcon,
  Schedule as ScheduleIcon,
} from "@mui/icons-material";
import { Button, Menu, MenuItem, Stack, Tab, Tabs, ThemeProvider, Tooltip, Typography } from "@mui/material";

import {
  CardBoard,
  CardBoardCampaignTitle,
  CardBoardCampaignTitleProps,
  CardBoardItemProps,
  CardBoardStatsItem,
  CardBoardStatsWrapper,
  Pagination,
  TextField,
  usePagination,
} from "@work4Labs/design-system";

import { ApplicationApi, SourcingCampaignApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { UseQueryResult, useQueries, useQuery } from "@tanstack/react-query";
import theme from "@theme";
import { ApplicationsReport, SourcingCampaign } from "@typings";
import { Logger } from "@utils";

import { useUserOrganization } from "@hooks";
import { useOrganizationRequestFormLink } from "@hooks/queries";

import { AddButton } from "../../common";

const DEFAULT_TAB = "all";
const CAMPAIGNS_ORDER: CardBoardCampaignTitleProps["status"][] = ["live", "paused", "pending", "finished"];

const sourcingCampaignStatusNormalizer = (status: string): CardBoardCampaignTitleProps["status"] => {
  switch (status) {
    case "Live":
      return "live";
    case "Closed":
    case "Finished":
      return "finished";
    case "Paused":
      return "paused";
    default:
      return "pending";
  }
};

const processCampaignReporting = (
  results: UseQueryResult<{ reports: ApplicationsReport; campaignID: string }, Error>[],
): Record<string, ApplicationsReport> =>
  results.reduce((acc, value) => {
    if (value.data != null) {
      acc[value.data.campaignID] = value.data.reports;
    }

    return acc;
  }, {});

const useSourcingCampaigns = (filter: string) => {
  const { t } = useTranslation(["sourcing-campaigns"]);
  loadTranslations("sourcing-campaigns");

  const [campaignsTab, setCampaignsTab] = useState<"all" | "ongoing" | "finished">(DEFAULT_TAB);

  // Get all sourcing campaigns from API.
  const sourcingCampaigns = useQuery<Array<SourcingCampaign>, AxiosError>({
    queryKey: [QUERY_KEYS.SOURCING_CAMPAIGNS],
    queryFn: () => SourcingCampaignApi.list(context.active()),
    meta: { errorMessage: t("sourcing-campaigns:loading.error") },
  });

  // Get reporting data for each campaign.
  // TODO: write a dedicated endpoint to optimize this.
  const sourcingCampaignsReporting = useQueries({
    queries:
      sourcingCampaigns.data?.map((campaign) => ({
        // Cannot use the same query key because we need to return a slightly different data structure to keep track
        // of the campaign_id.
        queryKey: [QUERY_KEYS.APPLICATION_REPORTS, "list", campaign.campaign_id],
        queryFn: async () => ({
          campaignID: campaign.campaign_id,
          reports: await ApplicationApi.getReporting(context.active(), { campaign_id: campaign.campaign_id }),
        }),
      })) ?? [],
    combine: processCampaignReporting,
  });

  // Filter the campaigns we don't want to display.
  const filteredData = useMemo<SourcingCampaign[]>(() => {
    if (sourcingCampaigns.data == null) return [];

    // Create a copy of the data to avoid mutating the original array.
    let filtered = [...sourcingCampaigns.data];

    if (filter) {
      filtered = filtered.filter((sourcingCampaign) =>
        sourcingCampaign.name.toLowerCase().includes(filter.toLowerCase()),
      );
    }

    // Sort sourcing campaigns per status, in this order.
    // 1. Live
    // 2. Paused
    // 3. Pending
    // 4. Finished
    filtered.sort((a, b) => {
      const statusA = sourcingCampaignStatusNormalizer(a.status);
      const statusB = sourcingCampaignStatusNormalizer(b.status);

      const priorityA = CAMPAIGNS_ORDER.indexOf(statusA);
      const priorityB = CAMPAIGNS_ORDER.indexOf(statusB);

      return priorityA - priorityB;
    });

    switch (campaignsTab) {
      case "ongoing":
        filtered = filtered.filter(
          (sourcingCampaign) => sourcingCampaignStatusNormalizer(sourcingCampaign.status) !== "finished",
        );
        break;
      case "finished":
        filtered = filtered.filter(
          (sourcingCampaign) => sourcingCampaignStatusNormalizer(sourcingCampaign.status) === "finished",
        );
        break;
    }

    return filtered;
  }, [sourcingCampaigns.data, campaignsTab, filter]);

  // Get pagination information for the current list of filtered campaigns.
  const pagination = usePagination(filteredData.length);

  // Filter the data to be shown on the current (active) page.
  const currentPageData = useMemo<SourcingCampaign[]>(() => {
    return filteredData.slice(
      pagination.currentPage * pagination.rowsPerPage,
      (pagination.currentPage + 1) * pagination.rowsPerPage,
    );
  }, [filteredData, pagination.currentPage, pagination.rowsPerPage]);

  // Convert list of campaigns to map, for our board component.
  const mappedData = useMemo<Record<string, SourcingCampaign>>(() => {
    // Convert data to be passed to CardBoard.
    // Results are guaranteed to preserve the order of the original data, since ES2015 preserves insertion order when
    // mapping objects.
    // https://stackoverflow.com/a/5525820
    return currentPageData.reduce((acc, campaign) => {
      acc[campaign.campaign_id] = campaign;
      return acc;
    }, {});
  }, [currentPageData]);

  return {
    data: mappedData,
    reportings: sourcingCampaignsReporting,
    isLoading: sourcingCampaigns.isLoading,
    pagination,
    campaignsTab,
    setCampaignsTab,
  };
};

// Returns the launch_live_date is it's there and else the start at if it's there too.
const getCampaignStartAt = (campaign: SourcingCampaign): string => {
  if (campaign.launch_live_date) {
    return campaign.launch_live_date;
  }

  // rely on launch_asap to display the date, because if true, the date is 0001-01-01
  if (!campaign.launch_asap && campaign.start_at) {
    return campaign.start_at;
  }

  return "";
};

const getSourcingCampaignTimeLeft = (campaign: SourcingCampaign, t: TFunction): string => {
  const normalizedStatus = sourcingCampaignStatusNormalizer(campaign.status);

  if (normalizedStatus === "paused") {
    return t("sourcing-campaigns:campaigns.stats.timeLeft.paused");
  } else if (normalizedStatus === "finished") {
    return t("sourcing-campaigns:campaigns.stats.timeLeft.finished");
  } else {
    const startDate = moment(getCampaignStartAt(campaign));

    // We want to compute the remaining time. If the campaign has not started yet
    // i.e. now() < start date then the remaining time is the duration of the campaign.
    // if the campaign has started, we use now() for the diff because time has passed since
    // we've started the campaign and thus the remaining time has decreased.
    const compareDate = moment() < startDate ? startDate : moment();
    const diff = moment(campaign.end_at).diff(compareDate, "days");

    if (diff > 0) {
      return t("sourcing-campaigns:campaigns.stats.timeLeft.days", { days: diff + 1 });
    }
    if (diff === 0) {
      return t("sourcing-campaigns:campaigns.stats.timeLeft.today");
    }

    // diff < 0 is not covered, because we expect the status of the campaign to be Finished at this point
    // We also don't want to force show Finished if we've past the end date, but the campaign actually got
    // extended and is running.
    return t("sourcing-campaigns:campaigns.stats.timeLeft.na");
  }
};

const getSourcingCampaignReactivity = (report: ApplicationsReport | undefined, t: TFunction): ReactNode => {
  if (!report?.applications_processed_time || report.applications_processed_time <= 0) {
    return t("sourcing-campaigns:campaigns.stats.reactivity.na");
  }

  let color: string;

  if (report.applications_processed_time <= 1) {
    color = "var(--color-palette-top-green-400)";
  } else if (report.applications_processed_time <= 3) {
    color = "var(--color-palette-medium-yellow-400)";
  } else {
    color = "var(--color-palette-alert-red-400)";
  }

  return (
    <span style={{ color }}>
      {t("sourcing-campaigns:campaigns.stats.reactivity.days", { count: report.applications_processed_time })}
    </span>
  );
};

const getSourcingCampaignProcessingRate = (report: ApplicationsReport | undefined, t: TFunction): ReactNode => {
  if (!report?.applications_processed_count || report.applications_processed_count <= 0) {
    return t("sourcing-campaigns:campaigns.stats.processing_rate.na");
  }

  const processingRate =
    Math.round(((100 * report.applications_processed_count) / report.qualified_applications_count) * 10) / 10;

  let color: string;

  if (processingRate >= 90) {
    color = "var(--color-palette-top-green-400)";
  } else if (processingRate >= 70) {
    color = "var(--color-palette-medium-yellow-400)";
  } else {
    color = "var(--color-palette-alert-red-400)";
  }

  return <span style={{ color }}>{processingRate}%</span>;
};

const ActionMenu = forwardRef<HTMLDivElement, { campaignID: string; priority: number }>(function ActionMenuComponent(
  { campaignID, priority },
  ref,
) {
  const { t } = useTranslation(["sourcing-campaigns"]);
  loadTranslations("sourcing-campaigns");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div data-priority={priority} ref={ref} onClick={(e) => e.stopPropagation()}>
        <Button
          id="actions-button"
          aria-controls={open ? "actions-button" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          variant="contained"
          color="deepPurple"
          sx={{
            border: 0,
          }}
        >
          {t("sourcing-campaigns:campaigns.stats.actions.label")}
        </Button>
        <Menu
          id="actions-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "actions-button",
          }}
        >
          <MenuItem>
            <Link href={`/sourcing-operations/${encodeURIComponent(campaignID)}/applications`}>
              <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
                {t("sourcing-campaigns:campaigns.stats.actions.applications")}
              </Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href={`/sourcing-operations/${encodeURIComponent(campaignID)}/analytics`}>
              <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
                {t("sourcing-campaigns:campaigns.stats.actions.analytics")}
              </Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href={`/sourcing-operations/${encodeURIComponent(campaignID)}/settings`}>
              <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
                {t("sourcing-campaigns:campaigns.stats.actions.settings")}
              </Typography>
            </Link>
          </MenuItem>
        </Menu>
      </div>
    </ThemeProvider>
  );
});

const SourcingCampaignElementMain: FC<{ campaign: SourcingCampaign }> = ({ campaign }) => {
  const { t } = useTranslation(["sourcing-campaigns"]);
  loadTranslations("sourcing-campaigns");

  return (
    <Tooltip
      title={campaign.name}
      placement="top"
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            color: "white",
            bgcolor: theme.palette.background.darker,
          }),
        },
      }}
    >
      <Link
        style={{ cursor: "pointer", flexGrow: 1, maxWidth: "100%", overflow: "hidden" }}
        href={`/sourcing-operations/${encodeURIComponent(campaign.campaign_id)}/applications`}
      >
        <CardBoardCampaignTitle
          title={campaign.name}
          status={sourcingCampaignStatusNormalizer(campaign.status)}
          statusText={t(`sourcing-campaigns:campaigns.statuses.${sourcingCampaignStatusNormalizer(campaign.status)}`)}
        />
      </Link>
    </Tooltip>
  );
};

const SourcingCampaignElementSecondary: FC<{
  campaign: SourcingCampaign;
  reportings: Record<string, ApplicationsReport>;
  layoutComputing: boolean;
}> = ({ campaign, reportings, layoutComputing }) => {
  const { t } = useTranslation(["sourcing-campaigns"]);
  loadTranslations("sourcing-campaigns");

  const { push } = useRouter();

  const menuRef = useRef<HTMLDivElement>(null);

  const campaignReport = useMemo(() => reportings[campaign.campaign_id], [reportings, campaign.campaign_id]);

  const newApplications = useMemo(() => {
    return campaignReport?.applications_per_statuses?.find((stat) => stat.label === "new")?.value ?? 0;
  }, [campaignReport]);

  return (
    <CardBoardStatsWrapper
      onClick={(e) => {
        if (menuRef.current?.contains(e.target as Node)) return;
        push(`/sourcing-operations/${encodeURIComponent(campaign.campaign_id)}/applications`).catch(Logger.error);
      }}
      sx={{ cursor: "pointer" }}
      forceStatic={layoutComputing}
    >
      <CardBoardStatsItem
        priority={0}
        icon={PersonSearchIcon}
        title={t("sourcing-campaigns:campaigns.stats.applications.default")}
        value={<span style={{ width: "3ch", flexShrink: 0 }}>{campaign.applications_count}</span>}
        badge={
          newApplications
            ? {
                content: t("sourcing-campaigns:campaigns.stats.applications.new", {
                  count: newApplications,
                  context: newApplications > 99 ? "overflow" : undefined,
                }),
              }
            : undefined
        }
        sx={{ minWidth: "18rem" }}
        // Fit numbers up to 9999. If we want to feature bigger numbers we can use a condensed form with a unit
        // (eg. 1.7M).
        valueSx={{ width: "4ch" }}
      />
      <CardBoardStatsItem
        priority={1}
        icon={ScheduleIcon}
        title={t("sourcing-campaigns:campaigns.stats.timeLeft.default")}
        value={getSourcingCampaignTimeLeft(campaign, t)}
        sx={{ width: "14rem", minWidth: 0, flexShrink: 0, flexGrow: 0, overflow: "hidden" }}
      />
      <CardBoardStatsItem
        priority={2}
        icon={DateRangeIcon}
        title={t("sourcing-campaigns:campaigns.stats.reactivity.default")}
        value={getSourcingCampaignReactivity(campaignReport, t)}
        sx={{ width: "14rem", minWidth: 0, flexShrink: 0, flexGrow: 0, overflow: "hidden" }}
      />
      <CardBoardStatsItem
        priority={3}
        icon={KeyboardDoubleArrowRightIcon}
        title={t("sourcing-campaigns:campaigns.stats.processing_rate.default")}
        value={getSourcingCampaignProcessingRate(campaignReport, t)}
        sx={{ width: "14rem", minWidth: 0, flexShrink: 0, flexGrow: 0, overflow: "hidden" }}
      />
      <ActionMenu ref={menuRef} campaignID={campaign.campaign_id} priority={0} />
    </CardBoardStatsWrapper>
  );
};

export const SourcingCampaignsPage = () => {
  const { t } = useTranslation(["sourcing-campaigns"]);
  loadTranslations("sourcing-campaigns");

  const organization = useUserOrganization();
  const requestFormLink = useOrganizationRequestFormLink(organization.data?.group_id);

  const { push } = useRouter();

  const [filter, setFilter] = useState<string>("");

  const {
    data: mappedData,
    isLoading,
    reportings,
    pagination,
    campaignsTab,
    setCampaignsTab,
  } = useSourcingCampaigns(filter);

  const sourcingCampaignRenderer = useCallback(
    (campaign: SourcingCampaign): CardBoardItemProps => ({
      id: `sourcing_operation_item_${campaign.campaign_id}`,
      main: <SourcingCampaignElementMain campaign={campaign} />,
      secondary: ({ layoutComputing }) => (
        <SourcingCampaignElementSecondary
          campaign={campaign}
          reportings={reportings}
          layoutComputing={layoutComputing ?? false}
        />
      ),
    }),
    [reportings],
  );

  return (
    <Stack
      alignItems="center"
      direction="column"
      alignSelf="center"
      width="100%"
      flexGrow={1}
      gap="var(--space-16)"
      padding="var(--space-24) var(--space-48) var(--space-16) var(--space-48)"
      maxWidth="128rem"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="start"
        gap="var(--space-16)"
        flexWrap="nowrap"
        width="100%"
      >
        <Typography
          color="var(--color-palette-base-800)"
          fontWeight={800}
          fontSize="var(--font-size-200)"
          lineHeight="120%"
        >
          {t("sourcing-campaigns:title")}
        </Typography>
        <Stack direction="column" alignItems="self-end" gap="var(--space-12)">
          <AddButton
            id="operation_request_button"
            onClick={() => {
              push(requestFormLink?.data?.link ?? "/sourcing-campaign").catch(Logger.error);
            }}
          >
            {t("sourcing-campaigns:newOperation")}
          </AddButton>
          <TextField
            value={filter}
            InputProps={{ sx: { alignSelf: "stretch" } }}
            onChange={(e) => setFilter(e.target.value)}
            placeholder={t("sourcing-campaigns:search.placeholder")}
          />
        </Stack>
      </Stack>
      <Stack gap="var(--space-12)" direction="column" alignItems="flex-start" width="100%" padding={0}>
        <Tabs
          id="sourcing_operation_category_filter"
          value={campaignsTab}
          onChange={(_, newValue: typeof campaignsTab) => setCampaignsTab(newValue)}
          sx={{
            ".MuiTabs-indicator": {
              backgroundColor: "var(--color-palette-deep-purple)",
            },
            ".MuiTab-root": {
              fontWeight: 400,
              fontSize: "var(--font-size-85)",
              color: "var(--color-palette-base-800)",
              padding: "var(--space-8)",
              ".MuiTouchRipple-root": {
                display: "none",
              },
              "&.Mui-selected": {
                color: "var(--color-palette-deep-purple)",
                fontWeight: 700,
              },
            },
          }}
        >
          <Tab label={t("sourcing-campaigns:tabs.all")} value="all" />
          <Tab label={t("sourcing-campaigns:tabs.ongoing")} value="ongoing" />
          <Tab label={t("sourcing-campaigns:tabs.finished")} value="finished" />
        </Tabs>
        <CardBoard
          id="sourcing_operation_list"
          mainProps={{ maxWidth: "40rem", minWidth: "20rem" }}
          data={mappedData}
          loading={isLoading}
          loadingRows={pagination.rowsPerPage}
          render={sourcingCampaignRenderer}
        />
      </Stack>
      <Pagination width="100%" {...pagination} rowsPerPageLabel={t("sourcing-campaigns:pagination.linesPerPage")} />
    </Stack>
  );
};
