import { useMemo } from "react";

import { ApplicationApi, JobsApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { useQuery } from "@tanstack/react-query";

export const useApplication = (applicationID: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS, applicationID],
    queryFn: () => ApplicationApi.get(context.active(), applicationID),
    // refetch the application every minute to ensure that the messages history is
    // up-to-date
    refetchInterval: 60 * 1000,
  });
};

export const useApplicationWithJobInfo = (applicationID: string) => {
  const application = useApplication(applicationID);

  const job = useQuery({
    queryKey: [QUERY_KEYS.JOBS, application.data?.job_id],
    queryFn: () => JobsApi.get(context.active(), application.data?.job_id ?? ""),
    enabled: !!application.data,
  });

  const applicationWithJob = useMemo(() => {
    if (!application.data || !job.data) {
      return application.data;
    }

    application.data.job_title = job.data.title;
    application.data.city = job.data.city;
    application.data.country_code = job.data.country_code;
    application.data.state = job.data.location_state;
    application.data.postal_code = job.data.postal_code;
    application.data.region = job.data.region;

    return application.data;
  }, [application.data, job.data]);

  return {
    ...application,
    data: applicationWithJob,
  };
};
