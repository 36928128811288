export enum JobContract {
  FULL_TIME = "full_time",
  PART_TIME = "part_time",
}

export enum JobStatus {
  ACTIVE = "ACTIVE",
  PROCESSING = "PROCESSING",
  INACTIVE = "INACTIVE",
}

export interface Job {
  id: string;

  title: string;
  location_state: string;
  city: string;
  region: string;
  postal_code: string;
  country_code: string;
}

export interface ListJobItem {
  id: string;
  title: string;
  status: JobStatus;
  category: string;
  city: string;
  region: string;
  country: string;
  contract: JobContract;
  distance: number;
}
