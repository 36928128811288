import { FC } from "react";

import { useTranslation } from "next-i18next";

import {
  CalendarToday as CalendarTodayIcon,
  Download as DownloadIcon,
  Phone as PhoneIcon,
  Share as ShareIcon,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip, styled } from "@mui/material";

import { ClickToCallAction, DownloadCandidateDocumentsAction, InterviewAction, ShareAction } from "@components";
import { loadTranslations } from "@lib";
import { Application, Candidate, InterviewsConfiguration, SourcingCampaign } from "@typings";

import { ActionsButton } from "./actions-button";
import { ReminderAction } from "./recruiter-reminder-action";

export const ActionIconButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  padding: `${theme.spacings[8]} ${theme.spacings[12]}`,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: theme.radius[2],
  border: `1px solid ${theme.palette.color.BASE[300] as string}`,
  "& svg": {
    fill: theme.palette.color.BASE[500],
  },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.color.BASE[100],
    "& svg": {
      fill: theme.palette.color.BASE[400],
    },
  },
}));

type Props = {
  application: Application;
  candidate: Candidate;
  sourcingCampaign: SourcingCampaign;
  interviewConfiguration?: InterviewsConfiguration;
};

export const ApplicationActions: FC<Props> = ({ application, candidate, sourcingCampaign, interviewConfiguration }) => {
  const { t } = useTranslation(["application-details"]);
  loadTranslations("application-details");

  return (
    <Box display="flex" flexDirection="row" gap="1rem">
      <Box id="application_shortcut_buttons" display="flex" flexDirection="row" gap="1rem">
        <Box>
          <ClickToCallAction
            applicationId={application.id}
            applicantName={candidate.first_name + " " + candidate.last_name}
            applicantPhone={candidate.phone}
            tooltipPlacement="bottom"
            render={({ onClick, disabled }) => (
              <Tooltip
                title={t("actions.call_candidate")}
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: (theme) => ({
                      color: "white",
                      bgcolor: theme.palette.background.darker,
                      textAlign: "center",
                      fontSize: "0.875rem",
                    }),
                  },
                }}
                arrow
              >
                <span>
                  <ActionIconButton onClick={onClick} disabled={disabled} id="quick_action_click_to_call">
                    <PhoneIcon
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                    />
                  </ActionIconButton>
                </span>
              </Tooltip>
            )}
          />
        </Box>

        {interviewConfiguration && (
          <InterviewAction
            interviewConfiguration={interviewConfiguration}
            sourcingCampaign={sourcingCampaign}
            applicationID={application.id}
            renderCreate={({ onClick, disabled }) => (
              <Tooltip
                title={t("actions.schedule_interview")}
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: (theme) => ({
                      color: "white",
                      bgcolor: theme.palette.background.darker,
                      textAlign: "center",
                      fontSize: "0.875rem",
                    }),
                  },
                }}
                arrow
              >
                <span>
                  <ActionIconButton onClick={onClick} disabled={disabled} id="quick_action_schedule_interview">
                    <CalendarTodayIcon sx={{ height: 20, width: 20 }} />
                  </ActionIconButton>
                </span>
              </Tooltip>
            )}
          />
        )}

        <Box>
          <ShareAction
            application={application}
            render={({ onClick }) => (
              <Tooltip
                title={t("actions.share_by_email")}
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: (theme) => ({
                      color: "white",
                      bgcolor: theme.palette.background.darker,
                      textAlign: "center",
                      fontSize: "0.875rem",
                    }),
                  },
                }}
                arrow
              >
                <ActionIconButton onClick={onClick} id="quick_action_share_profile">
                  <ShareIcon sx={{ height: 20, width: 20 }} />
                </ActionIconButton>
              </Tooltip>
            )}
          />
        </Box>

        <Box>
          <ReminderAction applicationID={application.id} />
        </Box>

        <Box>
          <DownloadCandidateDocumentsAction
            application={application}
            render={({ onClick, disabled }) => (
              <Tooltip
                title={t("actions.download_profile")}
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: (theme) => ({
                      color: "white",
                      bgcolor: theme.palette.background.darker,
                      textAlign: "center",
                      fontSize: "0.875rem",
                    }),
                  },
                }}
                arrow
              >
                <ActionIconButton
                  onClick={() => {
                    onClick();
                  }}
                  id="quick_action_download_profile"
                  disabled={disabled}
                >
                  <DownloadIcon sx={{ height: 20, width: 20 }} />
                </ActionIconButton>
              </Tooltip>
            )}
          />
        </Box>
      </Box>
      <ActionsButton
        application={application}
        candidate={candidate}
        interviewConfiguration={interviewConfiguration}
        sourcingCampaign={sourcingCampaign}
      />
    </Box>
  );
};
