import Image from "next/image";

import { FC } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import { Box, Button, Typography } from "@mui/material";

import { loadTranslations } from "@lib";

import filterEye from "../../public/assets/filter-eye.png";

interface Props {
  onReset: () => void;
}

export const EmptyRowsFallback: FC<Props> = ({ onReset }) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  return (
    <Box
      id="no_results"
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "1rem",
        backgroundColor: "white",
        gap: theme.spacings[24],
      })}
    >
      <Image src={filterEye} alt="no-data" />
      <Typography variant="body" textAlign="center" whiteSpace="pre-line">
        {t("no_results.label")}
      </Typography>
      <Button variant="outlined" color="deepPurple" onClick={onReset}>
        {t("no_results.button")}
      </Button>
    </Box>
  );
};

export const renderEmptyRowsFallback = ({ table }: { table: MRT_TableInstance<any> }) => (
  <EmptyRowsFallback
    onReset={() => {
      table.resetColumnFilters();
      table.resetGlobalFilter();
    }}
  />
);
