import { FC, useMemo } from "react";

import { MRT_Row } from "material-react-table";

import {
  Event as EventIcon,
  HourglassTopRounded as HourglassTopIcon,
  PhoneMissed as PhoneMissedIcon,
} from "@mui/icons-material";
import { Stack, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import { useOTELContext, useUserGroup } from "@hooks";
import { useFetchJobApplicationsList } from "@hooks/queries";

import { ListJobItemWithStats } from "../types";

const OverviewStat: FC<{ label: string; icon: OverridableComponent<SvgIconTypeMap> }> = ({ label, icon: Icon }) => (
  <Stack flexDirection="row" flexWrap="nowrap" gap={(theme) => theme.spacings[4]}>
    <Typography fontSize="1rem" fontWeight={700} lineHeight="150%" color={(theme) => theme.palette.color.BASE[600]}>
      {label}
    </Typography>
    <Icon
      sx={(theme) => ({
        height: 24,
        width: 24,
        color: theme.palette.error[600],
      })}
    />
  </Stack>
);

export const OverviewCell: FC<{ row: MRT_Row<ListJobItemWithStats> }> = ({ row }) => {
  const organizationName = useUserGroup();

  const ctx = useOTELContext();
  const { applications } = useFetchJobApplicationsList(ctx, organizationName, row.original.id);

  const didNotAnswerApplications = useMemo(
    () => applications.filter((application) => application.status === "did not answer").length,
    [applications],
  );

  const interviewApplications = useMemo(
    () => applications.filter((application) => application.status === "interview").length,
    [applications],
  );

  const pendingApplications = useMemo(
    () => applications.filter((application) => application.status === "validation pending").length,
    [applications],
  );

  return (
    <Stack flexDirection="row" flexWrap="nowrap" gap={(theme) => theme.spacings[12]}>
      <OverviewStat label={`${didNotAnswerApplications}`} icon={PhoneMissedIcon} />
      <OverviewStat label={`${interviewApplications}`} icon={EventIcon} />
      <OverviewStat label={`${pendingApplications}`} icon={HourglassTopIcon} />
    </Stack>
  );
};
