import React, { FC, useCallback, useState } from "react";

import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Cancel as CancelIcon, UploadFile as UploadFileIcon, CloudUpload as UploadIcon } from "@mui/icons-material";
import { Box, Button, Chip, Grid, MenuItem, ThemeProvider, Typography, useTheme } from "@mui/material";

import { Modal, Select } from "@work4Labs/design-system";

import { ApplicationApi, CreateDocumentsLogsParams, UploadDocumentsParams } from "@api";
import { BACKGROUND_COLOR, BORDER_COLOR, BORDER_RADIUS, DOCUMENT_TYPES, QUERY_KEYS, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DocumentsLogsType } from "@typings";

type Props = {
  open: boolean;
  closeModal: () => void;
  applicationID: string;
};

export const AddDocumentsModal: FC<Props> = ({ open, closeModal, applicationID }) => {
  const { t } = useTranslation(["application-documents"]);
  loadTranslations("application-documents");

  const [file, setFile] = useState<File | undefined>(undefined);
  const [documentType, setDocumentType] = useState<DOCUMENT_TYPES>(DOCUMENT_TYPES.Resume);

  const theme = useTheme();

  const queryClient = useQueryClient();
  const createDocumentsLogsMutation = useMutation({
    mutationFn: (data: CreateDocumentsLogsParams) => ApplicationApi.createDocumentsLogs(context.active(), data),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, applicationID],
        })
        .catch(() => {});

      toast.success(t("import_done"));
      setFile(undefined);
      closeModal();
    },
    onError: () => {
      toast.error(t("import_failed"));
    },
  });
  const uploadDocumentsMutation = useMutation({
    mutationFn: (data: UploadDocumentsParams) => ApplicationApi.uploadDocuments(context.active(), data),
    onSuccess: () => {
      createDocumentsLogsMutation.mutate({
        application_id: applicationID,
        body: {
          documents_type: documentType,
          log_type: DocumentsLogsType.SELF_UPLOAD,
        },
      });
    },
    onError: () => {
      toast.error(t("import_failed"));
    },
  });

  const onConfirmButton = useCallback(() => {
    if (file) {
      const data = new FormData();
      data.append("document", file);

      uploadDocumentsMutation.mutate({
        application_id: applicationID,
        document_type: documentType,
        body: data,
      });
    }
  }, [applicationID, file, documentType, uploadDocumentsMutation]);

  const modalCustomButtons = useCallback(() => {
    const buttonStyle = {
      fontSize: "1rem",
      fontWeight: "600",
      textTransform: "none",
      borderRadius: "8px",
    };
    return (
      <>
        <Button
          variant="outlined"
          onClick={closeModal}
          sx={{
            ...buttonStyle,
            color: theme.palette.primary.main,
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "#F2F3F7",
            },
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="outlined"
          onClick={onConfirmButton}
          sx={{
            ...buttonStyle,
            marginRight: "0.5rem",
            color: "white",
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
            "&:disabled": {
              backgroundColor: "var(--color-palette-base-100)",
            },
          }}
          disabled={!file}
        >
          {t("submit_request")}
        </Button>
      </>
    );
  }, [closeModal, onConfirmButton, t, theme, file]);

  return (
    <Modal
      isOpen={open}
      aria-label="application-documents-modal-import"
      aria-describedby="application-documents-modal-import"
      scroll="body"
      modalTitle={t("add_document_modal_title")}
      title={t("add_document_title")}
      modalIcon={<UploadIcon />}
      onClose={closeModal}
      onConfirm={onConfirmButton}
      customActions={modalCustomButtons}
      options={{
        maxWidth: "495px",
      }}
    >
      <ThemeProvider theme={theme}>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Grid item xs={12}>
            <Select
              renderValue={(selected) => t(`document_type_${selected}`)}
              value={documentType}
              onChange={(e) => setDocumentType(e.target.value as DOCUMENT_TYPES)}
              required
              label={t("choose_documents")}
            >
              {Object.values(DOCUMENT_TYPES).map((kind) => (
                <MenuItem key={kind} value={kind}>
                  {t(`document_type_${kind}`)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: BACKGROUND_COLOR.inputDefault,
              borderColor: BORDER_COLOR.inputBase,
              borderStyle: "dashed",
              borderWidth: "1px",
              borderRadius: BORDER_RADIUS[2],
            }}
          >
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              sx={{
                backgroundColor: "unset",
                border: "unset",
                "&:hover": { backgroundColor: "unset", border: "unset", boxShadow: "unset" },
                width: "100%",
                height: "150px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UploadFileIcon
                sx={{
                  width: "36px",
                  height: "36px",
                  color: TEXT_COLOR.lowestInfo,
                }}
              />
              <Typography variant="bodyCopyStrong" sx={{ color: TEXT_COLOR.lowInfo }}>
                {t("add_file")}
              </Typography>
              <Typography variant="tags" sx={{ color: TEXT_COLOR.lowInfo }}>
                {t("max_size")}
              </Typography>
              <input
                type="file"
                hidden
                accept=".pdf,.jpg,.png"
                onChange={(e) => {
                  if (e.target.files?.[0] && e.target.files[0].size < 1024 * 1024 * 10) {
                    setFile(e.target.files[0]);
                  }
                }}
              />
            </Button>
          </Grid>
          {!!file && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Chip
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {file?.name}
                      <div
                        onClick={() => {
                          setFile(undefined);
                        }}
                      >
                        <CancelIcon
                          sx={{
                            width: "24px",
                            height: "24px",
                            marginLeft: "8px",
                            marginTop: "8px",
                            color: `${TEXT_COLOR.lowestInfo} !important`,
                          }}
                        />
                      </div>
                    </Box>
                  }
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: BORDER_RADIUS[4],
                    border: `1px solid ${BORDER_COLOR.inputBase}`,

                    color: `${TEXT_COLOR.lowInfo}`,
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </ThemeProvider>
    </Modal>
  );
};
