import { ScoringAPI } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Logger } from "@utils";

export const useScoringEnabledQuery = (campaignID: string | undefined) =>
  useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGN_HAS_SCORING, campaignID],
    queryFn: () => ScoringAPI.hasEngine(context.active(), campaignID ?? ""),
    enabled: !!campaignID,
  });

export const useScoringEnabledQueries = (...campaignIDs: string[]) =>
  useQueries({
    queries: campaignIDs.map((campaignID) => ({
      queryKey: [QUERY_KEYS.CAMPAIGN_HAS_SCORING, campaignID, "batch"],
      queryFn: async () => ({
        campaignID,
        result: await ScoringAPI.hasEngine(context.active(), campaignID),
      }),
      enabled: !!campaignID,
    })),
    combine: (results): Record<string, boolean> =>
      results.reduce(
        (acc, queryResult) => {
          if (queryResult.isError) {
            Logger.error(queryResult.error);
            return acc;
          }

          if (queryResult.data) {
            acc[queryResult.data.campaignID] = queryResult.data.result;
          }

          return acc;
        },
        {} as Record<string, boolean>,
      ),
  });

export const useScoringQuery = (campaignID: string | undefined) =>
  useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_ALL_SCORES, campaignID],
    queryFn: () => ScoringAPI.getAllScores(context.active(), campaignID ?? ""),
    staleTime: Infinity,
    enabled: !!campaignID,
  });

export const useScoringQueries = (...campaignIDs: string[]) =>
  useQueries({
    queries: campaignIDs.map((campaignID) => ({
      queryKey: [QUERY_KEYS.APPLICATION_ALL_SCORES, campaignID],
      queryFn: () => ScoringAPI.getAllScores(context.active(), campaignID),
      enabled: !!campaignID,
    })),
    combine: (results): Record<string, number> =>
      results.reduce(
        (acc, queryResult) => {
          if (queryResult.isError) {
            Logger.error(queryResult.error);
            return acc;
          }

          if (queryResult.data) {
            return Object.assign(acc, queryResult.data);
          }

          return acc;
        },
        {} as Record<string, number>,
      ),
  });
