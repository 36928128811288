import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "next-i18next";

import { ApplicationApi, JobsApi } from "@api";
import { ManagedMaterialReactTable, Spinner, useManagedMaterialReactTable } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useQueries, useQuery } from "@tanstack/react-query";

import { useUserGroup } from "@hooks";

import { ListJobItemWithStats } from "./types";

import { RowActions } from "./cells";
import { INITIAL_COLUMNS_VISIBILITY } from "./constants";
import { useOnRowClick } from "./hooks";
import { useJobsGroupActions } from "./hooks/group-actions";
import { useJobsColumns } from "./jobs-columns";

export const JobsList = () => {
  const { t } = useTranslation(["jobs-list"]);
  loadTranslations("jobs-list");

  const organization = useUserGroup();

  const columns = useJobsColumns();

  const [filterableColumns] = useState<string[]>(["name", "city"]);

  const onRowClick = useOnRowClick();

  const translateColumn = useCallback((columnID: string) => t(`columns.${columnID}.shortName`), [t]);

  const jobsQuery = useQuery({
    queryKey: [QUERY_KEYS.JOBS_LIST],
    queryFn: () => JobsApi.list(context.active(), {}),
  });

  const applicationsQueries = useQueries({
    queries:
      jobsQuery.data?.jobs.map((job) => ({
        queryKey: [QUERY_KEYS.APPLICATIONS_LIST, "batch", { jobTitle: job.title }],
        queryFn: async () => ({
          applications: await ApplicationApi.list(context.active(), { job_id: job.id, campaign_id: "" }),
          jobTitle: job.title,
        }),
      })) ?? [],
  });

  const applicationsCountPerJob = useMemo(
    () =>
      applicationsQueries.reduce(
        (acc, count) => {
          if (count.data != null) acc[count.data.jobTitle] = count.data.applications.length;
          return acc;
        },
        {} as Record<string, number>,
      ),
    [applicationsQueries],
  );

  const jobs = useMemo<ListJobItemWithStats[]>(
    () =>
      jobsQuery.data?.jobs.map((job) => ({
        ...job,
        applicationsCount: applicationsCountPerJob[job.title] ?? 0,
      })) ?? ([] as ListJobItemWithStats[]),
    // Strangely, passing `applicationsCountPerJob` causes an infinite render loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(applicationsCountPerJob), jobsQuery.data?.jobs],
  );

  const table = useManagedMaterialReactTable({
    seizaFilters: {
      cacheKey: "jobs_list",
      initialColumnsVisibility: INITIAL_COLUMNS_VISIBILITY,
    },
    columns,
    data: jobs,
    state: { isLoading: jobsQuery.isLoading },
    initialState: {
      pagination: { pageSize: 50, pageIndex: 0 },
      columnPinning: { right: ["mrt-row-actions"] },
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    enableColumnFilters: false,
    enableGlobalFilter: true,
    enableHiding: false,
    enableRowActions: true,
    enableTopToolbar: false,
    enableFacetedValues: true,
    muiTableContainerProps: { id: "jobs_list" },
    muiTableBodyRowProps: ({ row }) => ({
      id: `job_item_${row.original.id}`,
      className: "job_item",
    }),
    muiTableBodyCellProps: ({ cell, row }) => ({
      onClick: () => {
        if (cell.id !== "mrt-row-select" && !cell.id.includes("mrt-row-actions")) onRowClick(row.original.id);
      },
    }),
    renderRowActions: ({ table, row }) => (
      <RowActions row={row} disabled={table.getSelectedRowModel().rows.length > 0} />
    ),
  });

  const { groupActions, groupActionsModals } = useJobsGroupActions(table);

  const { setColumnOrder } = table;

  // Ensure that the columns are in the correct order.
  useEffect(() => {
    setColumnOrder(["mrt-row-select", ...columns.map((column) => column.id!), "mrt-row-actions"]);
  }, [columns, setColumnOrder]);

  if (!organization) {
    return <Spinner style={{ position: "relative" }} />;
  }

  return (
    <>
      <ManagedMaterialReactTable
        table={table}
        title={t("title")}
        filterableColumns={filterableColumns}
        translateColumn={translateColumn}
        globalFilter
        showActiveFilters
        groupedActions={groupActions}
      />
      {groupActionsModals}
    </>
  );
};
