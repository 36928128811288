import { AxiosError } from "axios";
import moment, { Moment } from "moment";

import { Context } from "@opentelemetry/api";
import { Interview, InterviewsConfiguration, UpsertInterviewsConfigurationParams } from "@typings";
import { withSpan, withTraceParent } from "@utils";

import { interviewAPI } from "./api";

const getConfiguration = async (ctx: Context, campaignID: string): Promise<InterviewsConfiguration | undefined> =>
  withSpan(ctx, "GetConfiguration", async () => {
    const { data } = await interviewAPI
      .get<InterviewsConfiguration>(`/${campaignID}/configuration`, withTraceParent())
      .catch((err: AxiosError) => {
        if (err.response?.status === 404) {
          return { data: undefined };
        }

        throw err;
      });
    return data;
  });

const upsertConfiguration = async (ctx: Context, params: UpsertInterviewsConfigurationParams): Promise<void> =>
  withSpan(ctx, "UpsertConfiguration", async () => {
    const { data } = await interviewAPI.post(`/${params.campaign_id}/configuration`, params.payload, withTraceParent());
    return data;
  });

const deleteConfiguration = async (ctx: Context, campaign_id: string): Promise<void> =>
  withSpan(ctx, "DeleteConfiguration", async () => {
    const { data } = await interviewAPI.delete(`/${campaign_id}/configuration`, withTraceParent());
    return data;
  });

const getInterview = async (ctx: Context, applicationID: string): Promise<Interview[]> =>
  withSpan(ctx, "GetInterview", async () => {
    const { data } = await interviewAPI
      .get<Interview[]>(`/applications/${applicationID}/interviews`, withTraceParent())
      .catch((err: AxiosError) => {
        if (err.response?.status === 404) {
          return { data: [] };
        }

        throw err;
      });

    if (!data) {
      return data;
    }

    return data.map((interviewData: Interview) => {
      const interview = new Interview(
        interviewData.id,
        interviewData.application_id,
        interviewData.campaign_id,
        interviewData.recruiter_user_id,
        interviewData.date,
        interviewData.time_start,
        interviewData.time_end,
        interviewData.location,
        interviewData.stakeholders,
        interviewData.additional_information,
        interviewData.created_at,
        interviewData.deleted,
      );
      interview.time_start = moment(interview.time_start, "HH:mm");
      interview.time_end = moment(interview.time_end, "HH:mm");
      interview.date = moment(interview.date);

      return interview;
    });
  });

export interface InterviewCreation {
  application_id: string;
  campaign_id: string;

  date: Moment;
  time_start: Moment;
  time_end: Moment;

  location: string;
  stakeholders: string[];
  additional_information: string;
}

const createInterview = async (ctx: Context, interview: InterviewCreation): Promise<Interview> =>
  withSpan(ctx, "CreateInterview", async () => {
    const { data } = await interviewAPI.post(
      `/applications/${interview.application_id}/interviews`,
      {
        ...interview,

        date: moment(interview.date).format("YYYY-MM-DD"),
        time_start: moment(interview.time_start).format("HH:mm"),
        time_end: moment(interview.time_end).format("HH:mm"),
      },
      withTraceParent(),
    );
    return data;
  });

const deleteInterview = async (ctx: Context, interview_id: string): Promise<Interview> =>
  withSpan(ctx, "DeleteInterview", async () => {
    const { data } = await interviewAPI.delete(`/${interview_id}`, withTraceParent());
    return data;
  });

export const InterviewApi = {
  getConfiguration,
  upsertConfiguration,
  deleteConfiguration,
  getInterview,
  createInterview,
  deleteInterview,
};
