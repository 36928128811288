import {
  Cancel as CancelIcon,
  Description as DescriptionIcon,
  HourglassTop as HourglassTopIcon,
  PersonAdd as PersonAddIcon,
  PersonSearch as PersonSearchIcon,
  PhoneMissed as PhoneMissedIcon,
  Star as StarIcon,
  Today as TodayIcon,
  Work as WorkIcon,
} from "@mui/icons-material";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export const INITIAL_LEADS_COLUMNS_VISIBILITY = {
  name: true,
  email: false,
  status: true,
  phone: true,
  compatibility: true,
  job_title: true,
  job_location: true,
  last_interaction_date: true,
  created_at: true,
};

export const EXCLUDED_COLUMNS = ["mrt-row-select", "mrt-row-actions", "name"];

export type StatusFilter = {
  label: string;
  icon: OverridableComponent<SvgIconTypeMap>;
};

export const APPLICATION_STATUSES_FILTER: Record<string, StatusFilter> = {
  all: {
    label: "all",
    icon: PersonAddIcon,
  },
  new: {
    label: "new",
    icon: PersonSearchIcon,
  },
  "did not answer": {
    label: "did not answer",
    icon: PhoneMissedIcon,
  },
  "validation pending": {
    label: "validation pending",
    icon: HourglassTopIcon,
  },
  interview: {
    label: "interview",
    icon: TodayIcon,
  },
  offer: {
    label: "offer",
    icon: DescriptionIcon,
  },
  accepted: {
    label: "accepted",
    icon: WorkIcon,
  },
  refused: {
    label: "refused",
    icon: CancelIcon,
  },
  "added to talent pool": {
    label: "added to talent pool",
    icon: StarIcon,
  },
};
