import { TFunction } from "next-i18next";

// the back returns paths like: applicationID/filename.ext. This removes the applicationID in the prefix.
// It also tries to translate the filename if possible
export const extractFileName = (t: TFunction, path: string, applicationID: string, withTranslation: boolean) => {
  const filename = path.replace(applicationID + "/", "");
  const filenameParts = filename.split(".");

  return withTranslation ? `${t(filenameParts[0])}` : filename;
};
