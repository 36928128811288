import { URLSearchParams } from "next/dist/compiled/@edge-runtime/primitives/url";

import { Context } from "@opentelemetry/api";
import { Job, ListJobItem } from "@typings";
import { withSpan, withTraceParent } from "@utils";

import { jobsAPI } from "./api";
import { SortingOrder } from "./utils";

export interface ListJobsParams {
  sort_by_city?: SortingOrder;
  sort_by_title?: SortingOrder;
  search?: string;
  title?: string[];
  city?: string[];
  limit?: number;
  offset?: number;
}

export interface ListJobsResponse {
  jobs: ListJobItem[];
  total: number;
}

const parseListParams = (src: Record<string, any>) => {
  const searchParams = new URLSearchParams();

  Object.entries(src).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value
        .filter((v) => v != null)
        .forEach((v) => searchParams.append(key, typeof v === "string" ? v : JSON.stringify(v)));
    } else if (value != null) {
      searchParams.append(key, typeof value === "string" ? value : JSON.stringify(value));
    }
  });

  return searchParams.toString();
};

const list = async (ctx: Context, params: ListJobsParams): Promise<ListJobsResponse> =>
  withSpan(ctx, "ListJobs", async () => {
    const { data, headers } = await jobsAPI.get(
      "/list",
      withTraceParent({ params, paramsSerializer: parseListParams }),
    );
    return {
      jobs: data,
      total: parseInt((headers["x-total-count"] as string) ?? "0", 10),
    };
  });

const get = async (ctx: Context, id: string): Promise<Job> =>
  withSpan(ctx, "GetJob", async () => {
    const { data } = await jobsAPI.get(`/${id}`, withTraceParent());
    return data;
  });

export interface SearchJobsParams {
  organization_name: string;
  job_id?: Array<string>;
  title?: Array<string>;
  city?: Array<string>;
  postal_code?: Array<string>;
  category?: Array<string>;
  api_ref?: Array<string>;
  contract?: Array<string>;
  campaign_id?: Array<string>;
}

export const search = async (ctx: Context, params: SearchJobsParams): Promise<Array<Job>> =>
  withSpan(ctx, "SearchJobs", async () => {
    const { data } = await jobsAPI.get("/", withTraceParent({ params }));
    return data;
  });

export const deleteJob = async (id: string): Promise<void> => {
  await jobsAPI.delete(`/${id}`);
};

export const JobsApi = {
  get,
  search,
  list,
  deleteJob,
};
